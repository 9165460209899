<template>
  <ApiErrorDisplay v-if="apiError" v-bind="apiError"/>
  <div v-else :class="{
    [getPathClass()]: true,
    loading,
    }" class="wrapper h-100">
    <loading-spinner v-if="loading"/>
    <build-number v-if="buildNumber" :build-number="buildNumber"/>
    <messages/>
    <component
        :is="component.name"
        v-for="(component) in pageComponents"
        :key="component.id"
        :classes="component.classes"
        :componentData="component"
        :componentId="component.id"
        :components="component.components"
        v-bind="component.props"/>
  </div>
</template>

<script>
import Global from "@/_core/services/global";
import {useRoute, useRouter} from "vue-router";
import {ref, watch} from "vue";
import {addHtmlClass, getPathClass, removeHtmlClass} from "@/_core/mixins/classFunctions";
import useGlobalStore from "@/_core/store/useGlobalStore";
import useApiResponseProcessor from "@/_core/services/ApiResponseProcessor";
import useLoadingStore from "@/_core/store/useLoadingStore";
import {storeToRefs} from "pinia";
import Messages from "@/_core/components/messages/Messages.vue";
import LoadingSpinner from "@/_core/components/partials/LoadingSpinner.vue";
import useApiError from "@/_core/store/useApiErrorStore";
import ApiErrorDisplay from "@/_core/components/ApiErrorDisplay.vue";
import BuildNumber from "@/components/BuildNumber.vue";

export default {
  components: {BuildNumber, ApiErrorDisplay, LoadingSpinner, Messages},
  setup() {
    // noinspection JSUnresolvedReference - this is injected by webpack
    const buildNumber = BUILD_NUMBER;
    const {processResponse, processError} = useApiResponseProcessor()
    const {setLoading} = useLoadingStore();
    const {loading} = storeToRefs(useLoadingStore());
    const {apiError} = storeToRefs(useApiError());

    const pageComponents = ref([]);
    const pageMetaData = ref({
      title: "IO Dashboard"
    });
    const route = useRoute();
    const router = useRouter();

    async function getPage() {
      let url = route.path;
      const query = route.query;
      const queryString = Object
          .entries(query)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
      if (queryString) {
        url = url + '?' + queryString;
      }

      try {
        const page = await Global.getPage(url)

        await processResponse(page);

        pageComponents.value = page.data.components.filter(component => !!component);
        pageMetaData.value = page.data.metadata;

      } catch (error) {
        if (error.response.status === 403) {

          const redirect = route.path.match(/log(in|out)\/?$/) ? '' : `?redirect=${route.path}`
          return router.push(`/login${redirect}`);
        } else {
          return processError(error);
        }
      }

      return pageComponents.value;
    }

    async function pageLoad() {
      setLoading(true);
      //check we can access this route.
      try {
        const response = await Global.checkAuthenticated(route.path)
        //if we can, build the page
        if (response.authenticated) {
          addHtmlClass("authenticated");
          await useGlobalStore().fetchAndStoreGlobalData();
        } else {
          removeHtmlClass("authenticated");
          if (response.redirect) {
            await router.push(response.redirect);
          }
        }
        await getPage();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    watch(() => route.path, pageLoad)
    //pageLoad();

    return {
      apiError,
      getPathClass,
      pageComponents,
      loading,
      buildNumber
    }
  },
};
</script>

<style lang="scss">
@import "./scss/custom";
</style>
