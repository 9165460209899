<template>
  <div :class="classes">
    <div v-if="title" class="formTitle" v-html="wrappedTitle"></div>
    <template v-for="{content, type} in messages">
      <div :class="type" class="generalFormMessages">
        <div class="message" v-html="content"></div>
      </div>
    </template>
    <form :class="formDetails.classes" @submit.prevent="handleSubmit">

      <DynamicComponents :components="components"/> <!-- Inputs handled in here -->

      <div :class="formDetails.clearButton ? 'with-clear' : ''" class="formButtons">
        <button
            v-if="(!formDetails?.removeSubmit)"
            :disabled="isSubmitting"
            class="btn btn-primary"
            type="submit"
        >{{ submitText }}
          <img v-if="isSubmitting" alt="loading" class="form-submit loader-spinner"
               src="/assets/icons/loader.gif">
        </button>
        <button v-if="(formDetails?.clearButton)"
                :disabled="isSubmitting"
                class="btn btn-primary"
                type="button"
                @click="clearFormFields"
        >
          {{ formDetails.clearButtonText }}
        </button>
      </div>

    </form>
  </div>
</template>

<script setup>


import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import useFormStore from "@/_core/store/useFormStore";
import useTokenStore from "@/_core/store/useTokenStore";
import useApi from "@/_core/services/api";
import useApiResponseProcessor from "@/_core/services/ApiResponseProcessor";
import Global from "@/_core/services/global";
import classesProp from '@/_core/components/_properties/classes';
import DynamicComponents from "@/_core/components/partials/DynamicComponents.vue";
import randomString from "../../services/randomString";


const Api = useApi();
const {processResponse, processError} = useApiResponseProcessor(() => emit('refresh'));
const route = useRoute();
const formDetails = ref({});
const isSubmitting = ref(false);
const messages = ref([]);
const props = defineProps({
  ...classesProp,
  id: {
    type: String,
    required: true
  },
  components: {
    type: Array,
    required: true
  },
  submitText: {
    type: String,
    default: 'Submit'
  },
  action: {
    type: String,
    required: true
  },
  method: {
    type: String,
    default: 'POST'
  }
})

async function handleSubmit() {
  try {
    await Global.checkAuthenticated(route.path)
    //if we can, build the page

    isSubmitting.value = true;

    const {accessToken} = useTokenStore()
    const {fields, submitForm} = useFormStore()

    // Mark form as submitted in store
    submitForm(props.id)

    // Validation Check
    console.log('VALIDATION CHECK');
    let valid = true;
    fields.forEach(field => {
      console.log(field);
      if (field.formId === props.id && !field.isValid) {
        valid = false;
      }
    });

    if (!valid) {
      isSubmitting.value = false;
      messages.value = [
        {
          content: `<span class='error' data-event='${randomString(8)}'>There are errors in your submission, please check the highlighted red fields.</span>`,
          type: 'error',
        }
      ];
      return;
    }

    const formData = new FormData();
    fields.forEach(field => {
      formData.append(field.fieldName, field.value);
    });
    formData.append('_METHOD', props.method);

    const formRequest = {
      url: props.action,
      method: 'post',
      data: formData,
      headers: {
        'content-type': 'multipart/form-data',
        "Authorization": `Bearer ${accessToken}`
      }
    }
    try {
      const response = await Api.request(formRequest)
      await processResponse(response);

    } catch (error) {
      messages.value = await processError(error);
    } finally {
      isSubmitting.value = false;
    }
  } catch (error) {
    console.error(error);
  }
}

</script>


<style lang="scss">
.generalFormMessages {
  &.error {
    .message {
      margin-bottom: 20px;
      background: $form--error--color;
      padding: 10px;
      color: $brand--c1--hover-text;
    }
  }
}

form {
  .form-input--error {
    outline-color: $form--error--color;
    outline-style: solid;
  }
}

.form-submit {
  &.loader-spinner {
    width: 20px;
  }
}

.hidden-field-wrapper {
  display: none;
}
input.form-input--error {
  border: 1px solid #ff0000;
}
span.text-error {
  color: #ff0000;
}

</style>
