<template>
  <div :class="classes" class="form-field-container select-field-container">
   <label v-if="label" :class="'bmd-label-floating'" :for="id">{{ label }}{{ required ? '*' : '' }}</label>
    <tip-tap-editor
        v-if="enable_editor"
        v-bind:props="field"
    ></tip-tap-editor>
    <textarea
        v-else
        :id="id"
        :cols="cols"
        :name="fieldName"
        :placeholder="placeholder"
        :rows="rows"
        class="form-control"
        :class="{'form-input--error': !valid && isSubmitted}"
        @change="saveValue()"
        v-model="fieldValue"
        :disabled="disabled"

    >{{ value }}</textarea>
    <span v-if="!valid && isSubmitted" class="error text-error">{{ validationError }}</span>
    <div v-if="help" class="form-text">{{ help }}</div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import classResolver from "@/_core/services/classResolver";
import classesProp from '@/_core/components/_properties/classes';
import TipTapEditor from "../tiptap/TipTapEditor.vue";
import formFields from "../_properties/formFields";
import useFormStore from "@/_core/store/useFormStore";
import isValid from "@/_core/services/isValid";

const props = defineProps({
  ...classesProp,
  ...formFields,
  rows: {
    type: Number,
    default: 10
  },
  cols: {
    type: Number,
    default: 100
  },
  enable_editor: {
    type: Boolean,
    default: false
  },
});

const fieldValue = ref(props.value);
let valid = ref(true);
let validationError = ref('');

const classes = computed(() => classResolver({
  [props.classes]: true
}))

const formStore = useFormStore();
const isSubmitted = ref(false);
formStore.$subscribe((mutation, state) => { // Subscribe to formSubmission via formStore
  isSubmitted.value = formStore.isFormSubmitted(props.formId);
})

function saveValue() {
  const {validCheck, validError} = isValid(props.fieldName, props.validations, fieldValue.value);
  valid.value = validCheck;
  validationError.value = validError;
  const {setField} = formStore;
  setField(props.formId, props.fieldName, fieldValue.value, validCheck, validationError);
}

onMounted(() => { // Initialise field in formStore - important
  saveValue();
})

</script>
